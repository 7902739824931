.project {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

    .project h1 {
        margin-top: 50px;
        color: #3e497a;
        font-size: 35px;
    }

    .project .image-gallery {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

        .project .image-gallery img {
            width: 50%;
            border-radius: 10px;
            margin-top: 20px;
        }

    .project video {
        width: 50%;
        border-radius: 10px;
        margin-top: 20px;
    }

    .project p {
        font-size: 40px;
        color: #3e497a;
    }

    .project svg {
        font-size: 60px;
        color: #3e497a;
    }
